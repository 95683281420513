import React from 'react';
import {
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  TableCaption,
} from '@chakra-ui/react';
import AwbImg from '../Awb/AwbImg';
import { useSelector } from 'react-redux';
import { getB2bConfig } from '../../redux/slices/SettingsDetailsSlice';
const formatKey = key => {
  return key.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2');
};
const B2bCalculatorPriceCards = ({ calculatorDetails }) => {
  const b2bConfig = useSelector(getB2bConfig);
  const { servicePartners } = b2bConfig;
  const {
    priceList,
    pickUpPincode,
    deliveryPincode,
    insuranceType,
    paymentMode
  } = calculatorDetails || {};

  return priceList?.map((object, index) => {
    let {
      pickupCode,
      dropZone,
      Base_Fright_Charge,
      chargeableWeight,
      weight,
      Other_Handling_Charge,
      ODA_Charge,
      ADDITIONAL_CHG,
      Green_Tax,
      codCharge,
      FM_Charge,
      Fuel_Surcharge,
      Insurance,
      Total_Shipment_Charge,
      serviceProviderName
    } = object || {};
    
  let pickupZone = pickupCode?.split('_')[2];
    let baseFrightCharge =
      Number(Base_Fright_Charge) + Number(Fuel_Surcharge) + Number(FM_Charge);
   
    const newObject = {
      'pickup Zone': pickupZone,
      'Drop Zone': dropZone,
      'Pickup Pincode': pickUpPincode,
      'Drop Pincode': deliveryPincode,
      Weight: weight,
      'Chargeable Weight': chargeableWeight,
      'Base Fright Charge': baseFrightCharge,
      [insuranceType === 'owner' ? 'Doc. Charge' : 'Insurance']: Insurance,
      [paymentMode === 'TOPAY' ? 'TOPAY Charge' : 'Cod Charge']: codCharge,
      'ODA Charge': ODA_Charge,
      'ADDITIONAL CHG': ADDITIONAL_CHG,
      'Green Tax': Green_Tax,
      'Cod Charge': codCharge,
      'Other Handling Charge': Other_Handling_Charge,
      Total: Number(Total_Shipment_Charge),
    };
    if (paymentMode != "COD") {
      delete newObject["Cod Charge"];
    }
    return (
      <TableContainer
        key={index}
        py={1}
        px={4}
        border={'1px solid '}
        borderColor={'blackAlpha.500'}
        rounded={'md'}
      >
        <Table size={'sm'} variant="simple">
          <TableCaption
            m={0}
            placement="top"
            fontSize={22}
            fontWeight={'bold'}
            textTransform={'capitalize'}
          >
            <AwbImg
              shipmentPartner={serviceProviderName}
              w="full"
              objectFit={'cover'}
              alt={`${serviceProviderName}-Courier Name`}
              loading="lazy"
              mx="auto"
              icon={servicePartners[serviceProviderName]?.icon}
            />
          </TableCaption>
          <Tbody>
            {Object.entries(newObject).map(([key, value],index) => (
                <Tr
                key={key}
                bg={"inherit"}
                color={"inherit"}
                fontWeight={"inherit"}
              >
                <Td
                  p={1.5}
                  textTransform={"capitalize"}
                  fontWeight={"bold"}
                  borderRight={"1px solid "}
                  borderRightColor={"blackAlpha.100"}
                  fontSize={key === "Total" ? 18 : 15}
                  bg={index > 5 ? "gray.100" : "white"}
                  color={index > 5 ? "black" : "blackAlpha.700"}
                >
                  {key}
                </Td>
                <Td
                  mx={"auto"}
                  p={1.5}
                  fontSize={key === "Total" ? 18 : 16}
                  fontWeight={key === "Total" && "bold"}
                  bg={index > 5 ? "gray.100" : "white"}
                  color={index > 5 ? "black" : "blackAlpha.700"}
                >
                  {value}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  });
};

export default B2bCalculatorPriceCards;
